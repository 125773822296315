import React from 'react'
import styled from 'styled-components'

const Chip = (props) => {

    const { title = 'no data', bgColor, color } = props

    return (
        <ChipCommon bgColor={bgColor} color={color}>{title}</ChipCommon>
    )
}

export default Chip

const ChipCommon = styled.div`
    display: flex;
    padding: 4px 8px;
    width: fit-content;
    justify-content: center;
    align-items: center;
    background-color: ${p => p.bgColor};
    color: ${p => p.color};
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
`