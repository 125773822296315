import React from 'react'
import styled from "styled-components";
import PropTypes from 'prop-types';

const A = (props) => {

    const { fontSize, fontWeight, color, href, target, children } = props

    return (
        <AContent target={target} href={href} fontSize={fontSize} fontWeight={fontWeight} color={color}>{children}</AContent>
    )
}

A.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    href: PropTypes.string.isRequired,
    target: PropTypes.string
}

export default A

const AContent = styled.a`       
    color: ${p => p.color};
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight} !important;
    text-decoration: none;
    display: inline;
`