import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";

// IMAGES
import useLanding from "../../../hooks/useLanding";
import aden from "../../../assets/image/aden-logo.svg";
import alab from "../../../assets/image/logoacropolis.png";
import Image from "../../common/Image";

const Navbar = () => {
    const { navbarColor, collaboratorLogo } = useLanding();

    return (
        <NavContainer color={navbarColor}>
            <ImagesWrapper padding={!collaboratorLogo}>
                <div id="alab">
                    <Image height={"35px"} src={aden} />
                </div>
                <Divider />
                <ColaboratorImage isColaborator={collaboratorLogo}>
                    <Image
                        height={collaboratorLogo ? "50px" : "45px"}
                        src={collaboratorLogo || alab}
                    />
                </ColaboratorImage>
            </ImagesWrapper>
            <FlexWrapper>
                <Button
                    target={"_blank"}
                    href={process.env.REACT_APP_ACROPOLIS}
                    color="secondary"
                    variant="outlined"
                >
                    Iniciar sesión
                </Button>
                <Button href="#form" color="secondary" variant="contained">
                    Registrarme
                </Button>
            </FlexWrapper>
        </NavContainer>
    );
};

export default Navbar;

const NavContainer = styled.nav`
    width: calc(100% - 156px);
    padding: 25px 78px;
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    justify-content: space-between;
    background: #b31d15;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    z-index: 9;
    @media (max-width: 768px) {
        width: calc(100% - 24px);
        padding: 12px;
        height: 40px;
        align-items: center;
        justify-content: center;
        #navbtn {
            height: 30px;
            span {
                display: none;
            }
        }
    }
`;

const FlexWrapper = styled.div`
    display: flex;
    gap: 12px;
`;

const ImagesWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 12px;
    #alab {
        /* ${(p) => p.padding && `padding-top: 10px`}; */
    }
    @media (max-width: 768px) {
        width: 100%;
        display: none;
        justify-content: space-between;
    }
`;

const Divider = styled.div`
    /* margin-top: 10px; */
    height: 40px;
    width: 2px;
    background-color: #ffffff33;
`;

const ColaboratorImage = styled.div`
    background-color: ${(p) => (p.isColaborator ? "#fff" : "transparent")};
    border-radius: 8px;
`;
