import axios from "axios";


const CMS_URL = `${process.env.REACT_APP_CMS}api`

export const getMarksService = async () => {
    try {
        const req = await axios.get(`${CMS_URL}/home-acropolislab?populate=deep`)
        return req.data?.data
    } catch (error) {
        throw Error(error)
    }
}