const DEFAULT_COURSES = [
    { id: 1, id_curso: "6021", certificate: true },
    { id: 2, id_curso: "1336", certificate: true },
    { id: 3, id_curso: "5850", certificate: true },
    { id: 4, id_curso: "6789", certificate: true },
    { id: 5, id_curso: "1396", certificate: true },
];
const DEFAULT_SCORM = {
    title: "¿Te gustaría sumar estos cursos a tu propia plataforma?",
    description:
        "Si tu empresa cuenta con su propia plataforma educativa, puedes solicitarnos asistencia, para incluir los cursos de impacto dentro de la misma.",
    calendly_link: false,
    contact_email: false,
};
const STRAPI_URL = "https://cms.aden.org";

export const adapterLanding = (info) => {
    const { attributes } = info;

    const formatData = {
        id: attributes?.id_alab || 105,
        name: attributes?.commercial?.name || "Default",
        code: attributes?.code_form || "",
        id_group: attributes?.id_group || 0,
        courses: attributes?.list_oa || DEFAULT_COURSES,

        primaryColor: attributes?.primary_color || "#b31d15",
        sectionPlatform: attributes?.section_our_platform,
        sectionImpact: attributes?.section_impact,
        sectionCompanies: attributes?.section_companies,
        description: attributes?.description || "",
        subtitleHeader:
            attributes?.subtitle_header ||
            "Compartir, es parte de nuestro ADN. Impactar de forma positiva, es nuestro propósito.",
        titleHeader: attributes?.title_header || "¡Regístrate e impacta!",
        banner: {
            title:
                attributes?.banner?.title ||
                "ADEN te invita a compartir y expandir tu conocimiento",
            subtitle:
                attributes?.banner?.subtitle ||
                `En un mundo cambiante, convertirnos en co-creadores de nuevas realidades, nos inspira. 
            Por ello, te invitamos a expandir tu visión y la de tu empresa, a través de cursos de impacto. 
            Los mismos, podrás encontrarlos dentro de AcrópolisLab, nuestra plataforma de aprendizaje. 
            Accede de forma gratuita a contenido desarrollado por expertos y lleva tu conocimiento y el de tu equipo, al siguiente nivel. Puedes enviarles este enlace a tus colaboradores o colegas, para que también comiencen a cursar. 
            `,
            image: attributes?.banner?.image?.data?.attributes?.url
                ? `${STRAPI_URL}${attributes?.banner?.image?.data?.attributes?.url}`
                : "https://wallpaperaccess.com/full/2484157.jpg",
        },
        collaboratorLogo:
            attributes?.collaborators_logos?.data &&
            attributes?.collaborators_logos?.data[0]?.attributes?.url
                ? `${STRAPI_URL}${attributes?.collaborators_logos?.data[0]?.attributes?.url}`
                : null,
        scormBanner: attributes?.scorm_banner || DEFAULT_SCORM,
    };
    return formatData;
};
