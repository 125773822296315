import React from 'react'
import styled from 'styled-components'

const Footer = () => {

    // const { primaryColor } = useLanding()

    return (
        <FooterContent color={"#2f2d3d"}>
            Todos los derechos reservados | © ADEN { new Date().getFullYear() }
        </FooterContent>
    )
}

export default Footer

const FooterContent = styled.footer`
    background-color: ${p => p.color};
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    @media(max-width: 768px) {
        text-align: center;
        font-size: 14px;
    }
`