import { Button, CircularProgress } from "@mui/material";
import { Formik, Form as FormFormik, Field } from "formik";
import { useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { registerSchema } from "../../../../schemas/auth";
import DefaultField from "../../../common/InputField";
import A from "../../../common/A";
import Span from "../../../common/Span";
import useLanding from "../../../../hooks/useLanding";
import { registerUser, sendMail } from "../../../../services/api/sisapi";
import useUtilsForm from "../../../../hooks/useUtilsForm";
import {
    emailTemplateByUser,
    emailTemplateUser,
} from "../../../../utils/emailTemplate";

const ADEN_URL = process.env.REACT_APP_TERM;
const ACROPOLIS_URL = process.env.REACT_APP_ACROPOLIS;
const options = {
    style: {
        backgroundColor: "#222",
        fontSize: "24px",
        textAlign: "center",
    },
    closeStyle: {
        color: "lightcoral",
        fontSize: "16px",
    },
};

const Form = () => {
    const [loading, setLoading] = useState(false);
    const [countryCode, setCountryCode] = useState("");
    const [openSnackbar] = useSnackbar(options);
    const { id, courses, primaryColor, code, customCourses } = useLanding();
    const { countries } = useUtilsForm();

    console.log("courses", courses);
    console.log("customCourses", customCourses);

    const initialValues = {
        code: code || "",
        name: "",
        lastName: "",
        email: "",
        password: "",
        // confirmPass: "",
        country: "",
        // company: "",
        // phone: "",
        // team: "",
        term: true,
    };

    const utmMedium = id === 105 ? 688 : id === 113 ? 733 : 615;
    const handleSubmit = async (values) => {
        try {
            setLoading(true);
            const body = {
                first_name: values.name,
                last_name: values.lastName,
                email: values.email,
                // phone: values.phone,
                team: values?.team || false,
                password: values.password,
                courses: customCourses.map((c) => ({
                    id: c.id,
                    type: c.type,
                })),
                country_id: countryCode || false,
                company_id: values?.company?.empresa_id || false,
                company_name: values?.company?.empresa_nombre || false,
                group_project100: values.code?.toUpperCase(),
                utm_campaign: "Proyecto100",
                utm_source: 465,
                utm_medium: utmMedium,
                // landing_url: window.location.href,
            };
            const bodyUser = {
                from_email: "adencursos@aden.org",
                to_emails: [values.email],
                subject:
                    "Ingresa a la plataforma educativa de AcrópolisLab! | ADEN te acompaña",
                html_content: emailTemplateByUser(),
            };
            const bodyIternalEmail = {
                from_email: "tecnologia@aden.org",
                to_emails: "aden.proyecto100@aden.org",
                subject: "Nuevo registro de usuario desde CORPORATE",
                html_content: emailTemplateUser(values, code, courses),
            };

            await registerUser(id, body);
            await sendMail(bodyUser);
            await sendMail(bodyIternalEmail);
            setLoading(false);
            openSnackbar("Se creó correctamente el usuario");
            setTimeout(() => {
                window.open(ACROPOLIS_URL, "_self");
            }, 1000);
        } catch (error) {
            openSnackbar(String(error));
        } finally {
            setLoading(false);
        }
    };

    const handleChangeCountry = (countryValue) => {
        countries.forEach((country) => {
            if (country.code === countryValue.toUpperCase()) {
                setCountryCode(country.id);
            }
        });
    };

    return (
        <FormContainer>
            <FormWrapper>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={registerSchema}
                >
                    {({ setFieldValue, values }) => (
                        <FormF>
                            <DefaultField
                                name="code"
                                type="text"
                                label="Código Gift Card"
                                variant="outlined"
                                required
                                disabled={code ? true : false}
                            />
                            <Col>
                                <DefaultField
                                    name="name"
                                    type="text"
                                    label="Nombre"
                                    variant="outlined"
                                    required
                                />
                                <DefaultField
                                    name="lastName"
                                    type="text"
                                    label="Apellido"
                                    variant="outlined"
                                    required
                                />
                            </Col>
                            {/* <CompleteField
                                name="country"
                                label="País *"
                                options={countries}
                                freeSolo={false}
                                handleChangeCountry={handleChangeCountry}
                                setFieldValue={setFieldValue}
                                variant="outlined"
                            /> */}
                            {/* <InputPhone
                                name="phone"
                                label="Whatsapp"
                                value={values?.phone}
                                setFieldValue={setFieldValue}
                                variant="outlined"
                                required={true}
                                handleChangeCountry={handleChangeCountry}
                            /> */}
                            {/** EMAIL */}
                            <DefaultField
                                name="email"
                                type="text"
                                label="Correo electrónico"
                                variant="outlined"
                                onChange={(e) =>
                                    setFieldValue(
                                        "email",
                                        e.target.value.toLowerCase()
                                    )
                                }
                                values={values.email}
                                required
                            />
                            <DefaultField
                                name="password"
                                type="password"
                                label="Contraseña"
                                variant="outlined"
                                required
                            />
                            {/* <DefaultField
                                name="confirmPass"
                                type="password"
                                label="Repetir contraseña"
                                variant="outlined"
                                required
                            /> */}
                            {/* <Col>
                                <DefaultField
                                    name="team"
                                    type="text"
                                    label="Equipo"
                                    variant="outlined"
                                /> */}
                            {/* <CompleteField
                                name="company"
                                label="Empresa *"
                                freeSolo={true}
                                objectMod={{
                                    empresa_nombre: "value",
                                    empresa_id: false,
                                }}
                                setFieldValue={setFieldValue}
                                variant="outlined"
                            /> */}
                            {/* </Col> */}
                            <CenterMessage>
                                <Field required type="checkbox" name="term" />
                                <Span
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    Acepto los{" "}
                                    <A
                                        target={"_blank"}
                                        fontSize="16px"
                                        fontWeight="400"
                                        color={primaryColor}
                                        href={ADEN_URL}
                                    >
                                        Términos y Condiciones
                                    </A>
                                </Span>
                            </CenterMessage>
                            <FakeButton
                                // disabled={true}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {!loading ? (
                                    <span>Registrarme</span>
                                ) : (
                                    <FakeCircularProgress
                                        size={24}
                                        color="secondary"
                                    />
                                )}
                            </FakeButton>
                            {/* <CenterMessage>
                                <Text
                                    fontSize="16px"
                                    fontWeight="400"
                                    fontFamily="Lato"
                                >
                                    ¿Ya creaste tu cuenta?{" "}
                                </Text>
                                <A
                                    target={"_blank"}
                                    fontSize="16px"
                                    fontWeight="700"
                                    color={primaryColor}
                                    href={ACROPOLIS_URL}
                                >
                                    Iniciar sesión
                                </A>
                            </CenterMessage> */}
                        </FormF>
                    )}
                </Formik>
            </FormWrapper>
        </FormContainer>
    );
};

export default Form;

const FormContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 2rem 0;
    /* z-index: 9999; */
    @media (max-width: 768px) {
        #title-form {
            text-align: center;
            font-size: 24px;
        }
    }
`;

const FormWrapper = styled.div`
    width: 497px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
        0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    border-radius: 20px;
    @media (max-width: 768px) {
        width: auto;
    }
`;

const FormF = styled(FormFormik)`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px auto;
    width: 90%;
    #submit {
        margin: 0 auto;
        width: 150px;
        margin-top: 20px;
    }
`;

const Col = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`;

const CenterMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

const FakeButton = styled(Button)`
    font-size: 23px;
`;

const FakeCircularProgress = styled(CircularProgress)`
    padding: 2px 0;
`;
