import React, { useState } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = (props) => {

    const { src, width, height, id = false } = props

    const [isLoad, setIsLoad] = useState(false)

    return (
        <Wrapper isLoad={isLoad}>
            <img id={id} src={src} width={width} height={height} alt="Landing" onLoad={() => setIsLoad(true)}/>
        </Wrapper>
    )
}

Image.propTypes = {
    src: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

export default Image

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    opacity: ${p => p.isLoad ? '1' : '0'};
    transition: ease-in-out 1.5s all;
`