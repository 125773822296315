import React from "react";
import styled from "styled-components";
import Chip from "../../../common/Chip";
import Text from "../../../common/Text";

const CardCarousel = (props) => {
    const { image, name, description, certificate } = props;

    return (
        <CardContainer bg={image}>
            <Gradient />
            <div id={"id"}>
                {certificate && (
                    <Chip
                        title="ACTIVIDAD + CERTIFICADO"
                        bgColor="#b31d15"
                        color="#fff"
                    />
                )}
            </div>
            <Data>
                <Text
                    id="title-card"
                    color={"#fff"}
                    fontFamily="Lato"
                    fontSize="20px"
                    fontWeight="700"
                >
                    {name}
                </Text>
                <Scroll>
                    <Text
                        id="desc-card"
                        color={"#fff"}
                        fontFamily="Lato"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        {description}
                    </Text>
                </Scroll>
            </Data>
        </CardContainer>
    );
};

export default CardCarousel;

const CardContainer = styled.div`
    width: 520px;
    min-width: 520px;
    height: 350px;
    border-radius: 20px;
    background-image: url(${(p) => p.bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    position: relative;
    #id {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
    @media (max-width: 768px) {
        width: 100%;
        min-width: auto;
        display: flex;
        justify-content: center;
        #id {
            margin-top: 16px;
            position: static;
            font-size: 10px;
        }
    }
`;

const Gradient = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
    background: rgb(17, 17, 17);
    background: linear-gradient(
        0deg,
        rgba(17, 17, 17, 0.95) 0%,
        rgba(44, 44, 44, 0) 80%
    );
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    @media (max-width: 768px) {
        background: linear-gradient(
            0deg,
            rgba(17, 17, 17, 0.9) 60%,
            rgba(44, 44, 44, 0) 100%
        );
    }
`;

const Data = styled.div`
    width: calc(100% - 32px);
    height: 35%;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
    @media (max-width: 768px) {
        height: 50%;
        #title-card {
            font-size: 18px;
            margin-bottom: 16px;
        }
        #desc-card {
            font-size: 14px;
        }
    }
`;

const Scroll = styled.div`
    max-height: 80px;
    overflow-y: auto;
    @media (max-width: 768px) {
        max-height: 120px;
    }
`;
