import React from 'react'
import styled from 'styled-components'

const GridContent = (props) => {

    const { col = '1fr', height , margin, gap, mediaQuery = false, children } = props

    return (
        <Grid margin={margin} col={col} height={height} gap={gap} mediaQuery={mediaQuery}>{children}</Grid>
    )
}

export default GridContent

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${p => p.col};
    width: 100%;
    height: ${p => p.height};
    gap: ${p => p.gap};
    margin: ${p => p.margin};
    ${p => p.mediaQuery && p.mediaQuery}
`