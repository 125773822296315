import React, { useState } from 'react'
import styled from 'styled-components'
import banner from "../../../assets/image/banner_leader.png";
import Text from '../../common/Text'
import { Button } from '@mui/material';
import DownloadScrom from '../Options/components/DownloadScorm';
import useLanding from '../../../hooks/useLanding';

const RequestScorm = () => {

    const [openEmail, setOpenEmail] = useState(false)
    const { scormBanner } = useLanding()
    const {
        title, description, calendly_link, contact_email
    } = scormBanner

    const onCloseEmail = () => setOpenEmail(false)
    const onOpenEmail = () => setOpenEmail(true)

    return (
        <BannerWrapper>
            <Card>
                <Info>
                    <Text fontSize="14px" color="#fff">IMPACTA EN TU EMPRESA</Text>
                    <Text fontSize="28px" color="#fff" fontWeight="700">{title || '¿Te gustaría sumar estos cursos a tu propia plataforma?'}</Text>
                    <Text fontSize="14px" color="#fff">{description || 'Si tu empresa cuenta con su propia plataforma educativa, puedes solicitarnos asistencia, para incluir los cursos de impacto dentro de la misma.'}</Text>
                    <Buttons>
                        {calendly_link ? <ButtonFake href={calendly_link}>Agendar consulta</ButtonFake> : <ButtonFake onClick={onOpenEmail}>Solicitar cursos</ButtonFake>}
                        {contact_email && <ButtonFake href={`mailto:${contact_email}`}>Solicitar info</ButtonFake>}
                    </Buttons>
                </Info>
                <ImageWrapper img={banner} />
            </Card>
            <DownloadScrom open={openEmail} onClose={onCloseEmail}/>
        </BannerWrapper>
    )
}

export default RequestScorm

const BannerWrapper = styled.section`
    height: auto;
    width: calc(100% - 300px);
    padding: 0 150px;
    display: flex;
    margin: 5% 0;
    justify-content: space-between;
    @media (max-width: 768px){
        padding: 12px; 
        width: calc(100% - 24px);
    }
`

const Card = styled.div`
    border-radius: 20px;
    background-color: #2f2d3d;
    min-height: 310px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 768px){
        display: flex;
        height: auto;
        padding: 12px 0;
        img{
            display: none;
        }
    }
`

const Info = styled.div`
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    padding-left: 32px;
    gap: 24px;
    flex-direction: column;
    @media (max-width: 768px){
        text-align: center;
        align-items: center;
    }
`

const ButtonFake = styled(Button)`
    background-color: #3F3D56 !important;
    color: #fff !important;
`

const ImageWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    background-image: url(${p => p.img});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    object-fit: contain;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    
    /* #banner {
    } */
`

const Buttons = styled.div`
    display: flex;
    gap: 12px;
`