import React from "react";
import styled from "styled-components";
import useLanding from "../../../hooks/useLanding";
import Text from "../../common/Text";
import CardCarousel from "./components/CardCarousel";
import Carousel from "./components/Carousel";

const Courses = () => {
    const { primaryColor, textColor, courses, customCourses } = useLanding();

    if (!courses || !customCourses) {
        return <div />;
    }

    const coursesMapped = customCourses.map((item) => {
        if (item.type === "oa") {
            const newCourse = courses.find(
                (course) => course.id.toString() === item.id
            );

            if (newCourse) {
                return {
                    ...item,
                    url_image: newCourse.image,
                };
            } else {
                return item;
            }
        }
        return item;
    });

    const cards = coursesMapped.map((item) => (
        <CardCarousel
            certificate={item.certificate}
            image={item.url_image}
            name={item.nombre}
            description={item.description}
        />
    ));

    return (
        <Content>
            <Text color={primaryColor} fontSize="24px" fontWeight="700">
                APRENDE · COMPARTE · IMPACTA
            </Text>
            <Text
                textAlign={"center"}
                id="titleCourse"
                color={textColor}
                fontSize="48px"
                fontWeight="600"
            >
                {courses.length}
                {courses.length > 1 ? " cursos" : " curso"} de formación
                ejecutiva
            </Text>
            <Carousel slides={cards} />
        </Content>
    );
};

export default Courses;

const Content = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
    min-height: 350px;
    @media (max-width: 768px) {
        text-align: center;
        padding: 1rem;
        min-height: auto;
        #titleCourse {
            font-size: 32px;
            span {
                font-size: 32px;
            }
        }
    }
`;
