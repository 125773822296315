import React from 'react'
import styled from "styled-components";
import GridContent from '../../common/GridContent';
import Image from '../../common/Image';
import Text from '../../common/Text';
import { Button } from '@mui/material';
import useLanding from '../../../hooks/useLanding';
import ReactMarkdown from 'react-markdown'

const mq = `@media(max-width: 768px) {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    width: calc(100% - 3rem);
    height: 80vh;
}`

const Banner = () => {

    const { banner } = useLanding()

    return (
        <GridContent col="1.1fr 1fr" height="calc(100vh - 96px)" mediaQuery={mq}>
            <Content>
                <Text id="title" fontSize="48px" fontWeight="700" color={"#312F4D"}>{banner?.title}</Text>
                <Text id="subtitle" fontSize="16px" color="#666"><ReactMarkdown>{banner?.subtitle}</ReactMarkdown></Text>
                <Buttons>
                    {/* <Button href={process.env.REACT_APP_ADEN} target="_blank" color="primary" variant='contained'>Conocer más</Button> */}
                    <Button href='#form' variant='contained' color="primary">Registrarme</Button>
                </Buttons>
            </Content>
            <ImageContent>
                <Image width="100%" height="100%" src={banner?.image}/>
            </ImageContent>
        </GridContent>
    )
}

export default Banner

const ImageContent = styled.div`
    width: 100%;
    height: calc(100vh - 96px);
    img{
        object-fit: cover;
    }
    @media(max-width: 768px) {
        display: none;
    }
`

const Content = styled.div`
    display: flex;
    justify-content: center;
    max-height: calc(100vh - 96px);
    gap: 24px;
    align-items: center;
    flex-direction: column;
    padding: 0 6rem;
    animation: fadeInLeft linear 1.5s;
    @media(max-width: 768px) {
        text-align: center;
        padding: 0;
        #title{
            font-size: 32px;
        }
        #subtitle{
            font-size: 14px;
        }
    }
    @keyframes fadeInLeft {
        0% {
            opacity:0;
            -webkit-transform:translate3d(-100%,0,0);
            transform:translate3d(-100%,0,0)
        }
        50%{
            opacity:0;
        }
        to {
            opacity:1;
            -webkit-transform:translateZ(0);
            transform:translateZ(0)
        }
    }
`

const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 12px;
    @media(max-width: 768px) {
        justify-content: center;
    }
`