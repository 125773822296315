import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import Loading from "../components/common/Loading";
import useCourses from "../hooks/useCourses";
import useMarks from "../hooks/useMarks";
import useParams from "../hooks/useParams";
import { getOndemand } from "../services/api/ondemand";

export const LandingContext = createContext();

const LandingDataProvider = (props) => {
    const { children } = props;

    const [landingInfo, setLandingInfo] = useState(null);

    const [code] = useParams();
    const { courses, customCourses } = useCourses(
        landingInfo?.id || false,
        landingInfo?.courses
    );
    const { marks } = useMarks();

    useEffect(() => {
        !landingInfo && getDataLanding();
        // eslint-disable-next-line
    }, [landingInfo]);

    // Traigo la data de la landing
    const getDataLanding = useCallback(async () => {
        try {
            const req = await getOndemand(code);
            setLandingInfo(req);
        } catch (error) {
            alert("ERROR");
        }
        // eslint-disable-next-line
    }, [landingInfo]);

    // METO EN MEMORIA LA INFORMACION DE LA LANDING
    const contextLanding = useMemo(() => ({ ...landingInfo }), [landingInfo]);

    return (
        <LandingContext.Provider
            value={{ ...contextLanding, courses, marks, code, customCourses }}
        >
            {!landingInfo ? <Loading /> : children}
        </LandingContext.Provider>
    );
};

export default LandingDataProvider;
