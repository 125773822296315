import axios from "axios";

const SISAPI_URL = process.env.REACT_APP_SISAPI;
const REPOAPI_URL = process.env.REACT_APP_REPOAPI;

const CONFIG = {
    headers: {
        Authorization: `c7121220-480f-497a-9d25-f6b742472e2e`,
    },
};

export const sendMail = async (body) => {
    try {
        const { data } = await axios.post(
            SISAPI_URL + "/v1/acropolis/email/send",
            body,
            CONFIG
        );
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

export const registerUser = async (id_ic, body) => {
    try {
        const { data } = await axios.post(
            SISAPI_URL + "/v1/acropolis/auth/proyecto100/register",
            body,
            CONFIG
        );
        return data;
    } catch (error) {
        if (!!error.response.data.error.detail) {
            throw new Error(error.response.data.error.detail);
        } else {
            throw new Error(error.response.data.error);
        }
    }
};

export const getCountries = async () => {
    try {
        const { data } = await axios.get(
            SISAPI_URL + "/v1/acropolis/miscs/countries"
        );
        return data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};

export const getCompanies = async () => {
    try {
        const { data } = await axios.get(
            REPOAPI_URL + "/v1/repo_aden/miscs/empresas"
        );
        return data.response_data;
    } catch (error) {
        throw new Error(error);
    }
};
