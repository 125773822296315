
const useParams = () => {

    const rule = '/'
    const regex = RegExp(rule, "")
    const all = window.location.pathname.replace(regex, '')?.split("/") || false

    return all
}

export default useParams