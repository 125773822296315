import React from "react";
import styled from "styled-components";
import useLanding from "../../../../hooks/useLanding";
import Text from "../../../common/Text";
import parse from "html-react-parser";

const List = () => {
    const { code, description } = useLanding();

    if (!!code) {
        return (
            <Container>
                {parse(description) || (
                    <>
                        <Text fontSize={"22px"} fontWeight="bold">
                            Regístrate siguiendo estos pasos:
                        </Text>
                        <ListItem>
                            <Text fontWeight="bold" color="#666">
                                1) Completa el formulario de inscripción.
                            </Text>
                            <Text fontWeight="bold" color="#666">
                                2) Expande tu conocimiento.
                            </Text>
                            <Text fontWeight="bold" color="#666">
                                3) Al finalizar, descarga tu certificado
                                AcrópolisLab.
                            </Text>
                        </ListItem>
                    </>
                )}
            </Container>
        );
    }

    return (
        <Container>
            <Text fontSize={"22px"} fontWeight="bold">
                Regístrate siguiendo estos pasos:
            </Text>
            <ListItem>
                <Text fontWeight="bold" color="#666">
                    1) Ingresa el código que tu Asesor Académico te compartió.
                </Text>
                <Text fontWeight="bold" color="#666">
                    2) Completa el formulario de inscripción.
                </Text>
                <Text fontWeight="bold" color="#666">
                    3) Comienza el cursado.
                </Text>
                <Text fontWeight="bold" color="#666">
                    4) Al finalizar, descarga tu certificado AcrópolisLab.
                </Text>
            </ListItem>
        </Container>
    );
};

export default List;

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const ListItem = styled.div`
    margin-top: 3rem;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: flex-start;
`;
