import * as yup from 'yup';

export const registerSchema = yup.object().shape({
    code: yup
        .string()
        .min(5, 'Mínimo 5 caracteres')
        .trim()
        .max(8, 'Máximo 8 caracteres')
        .required('Código requerido'),
    name: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres')
        .required('Nombre requerido'),
    term: yup
        .boolean()
        .required('Nombre requerido'),
    lastName: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres')
        .required('Apellido requerido'),
    // phone: yup
    //     .string()
    //     .min(8, 'Mínimo 8 caracteres')
    //     .max(16, 'Máximo 16 caracteres')
    //     .required('Telefono requerido'),
    team: yup
        .string()
        .min(3, 'Mínimo 3 caracteres')
        .max(16, 'Máximo 16 caracteres'),
    email: yup
        .string()
        .email('Formato incorrecto')
        .required('Correo electrónico requerido')
        .max(320, 'Máximo 320 caracteres'),
    // country: yup
    //     .object()
    //     .nullable()
    //     .required("País requerido"),
    password: yup
        .string()
        .min(8, 'Mínimo 8 caracteres')
        .max(50, 'Máximo 50 caracteres')
        // .matches(/^.*(((?=.*[a-z]){1})((?=.*[A-Z]){1}))(((?=.*\W){1})|(?=.*[0-9])).*$/, 'La contraseña debe contener al menos una mayuscula, una minuscula, un caracter numérico y un caracter especial')
        .required('Contraseña requerida'),
});