import { useEffect, useState } from "react"
import { getMarksService } from "../services/api/strapi"

const useMarks = () => {

    const [marksArray, setMarksArray] = useState(null)

    useEffect(() => {getMarks()}, [])

    const getMarks = async () => {
        try {
            const req = await getMarksService()
            setMarksArray(req?.attributes?.companies?.data)
        } catch (error) {
            console.error(error);
        }
    }


    return { marks: marksArray }
}

export default useMarks