import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import Text from "../../common/Text";
import useLanding from "../../../hooks/useLanding";

const Marks = () => {
    const { marks } = useLanding();

    const CMS_URL = process.env.REACT_APP_CMS;

    const [arrayFormat, setArrayFormat] = useState([]);

    useEffect(() => {
        if (!!marks && arrayFormat.length === 0) {
            let newArray = []
            for (let index = 0; index < marks.length; index += 7) {
                const subArray = marks.slice(index, index + 7);
                newArray.push({
                    subArray: subArray,
                });
            }
            setArrayFormat(newArray)
        }
    }, [marks, arrayFormat.length]);

    return (
        <MarksWrapper>
            <Text id="fontTitle" fontSize="48px" fontWeight="700">
                Empresas que confían en ADEN
            </Text>
            <Text fontSize="18px" fontWeight="400" color="#616161">
                Más de 5.000 empresas en América Latina avalan la confiabilidad
                y logros del trabajo realizado por ADEN Educational Group
            </Text>
            {arrayFormat.length > 0 && (
                <CarouselFake
                    transitionMs={1000}
                    showArrows={false}
                    autoPlaySpeed={5000}
                    itemsToShow={1}
                    enableAutoPlay
                >
                    {arrayFormat.map((marks) => (
                        <WrapperAd>
                            <BrandWrapper>
                                {marks.subArray.map((subMarks) => (
                                    <img
                                        alt={subMarks.id}
                                        width="130px"
                                        key={subMarks.id}
                                        src={`${CMS_URL}${subMarks.attributes.url}`}
                                    />
                                ))}
                            </BrandWrapper>
                        </WrapperAd>
                    ))}
                </CarouselFake>
            )}
        </MarksWrapper>
    );
};

export default Marks;

const MarksWrapper = styled.section`
    padding-top: 3rem;
    height: fit-content;
    width: 100%;
    background-color: #f0eff5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const CarouselFake = styled(Carousel)`
    height: 150px;
    position: relative;
    width: 80%;
    /* padding: 0.5rem; */
    background: transparent;

    .rec-slider-container {
        margin: 0;
    }
    .rec.rec-pagination {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        display: none;
        button {
            background: #b5b6b6;
            box-shadow: none;
        }
        .rec-dot_active {
            border: none;
            background-color: #fff;
        }
    }
`;

const WrapperAd = styled.div`
    width: calc(100% - 3rem);
    padding: 1rem;
    background-color: transparent;
    height: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2rem;
`;

const BrandWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
`;
