import { useEffect, useState } from "react";

const REPO_URL = "https://repositorio-api.aden.org/api";
const VERSION = "v1";

const useCourses = (id, coursesParams) => {
    const [courses, setCourses] = useState(null);
    const [customCourses, setCustomCourses] = useState(null);

    useEffect(() => {
        id && getCourses();

        // eslint-disable-next-line
    }, [id]);

    const getCourses = async () => {
        try {
            const data = await fetch(
                `${REPO_URL}/${VERSION}/repo_aden/ondemand/courses/own_by_ondemand/${id}`
            );
            const { response_data } = await data.json();
            let result = [];
            response_data.forEach((course) => {
                const isExist = coursesParams.some(
                    (c) => parseInt(c.id_curso) === course.id
                );
                const isDuplicate = result.some(
                    (c) => parseInt(c.id) === course.id
                );
                if (isExist && !isDuplicate) {
                    result = [...result, course];
                }
            });
            setCourses(result);

            setCustomCourses(
                coursesParams.map((course) => ({
                    id: course.id_curso,
                    type: course.type,
                    url_image: course.url_image,
                    description: course.description,
                    certificate: course.certificate,
                    nombre: course.nombre,
                }))
            );
        } catch (error) {
            setCourses(false);
        }
    };

    return { courses, customCourses };
};

export default useCourses;
