import React from "react";
import Banner from "../components/shared/Banner/Banner";
import Courses from "../components/shared/Courses/Courses";
import Footer from "../components/shared/Footer/Footer";
import Marks from "../components/shared/Marks/Marks";
import Navbar from "../components/shared/Navbar/Navbar";
// import Options from '../components/shared/Options/Options'
import Register from "../components/shared/Regiter/Register";
import RequestScorm from "../components/shared/RequestScorm/RequestScorm";
import Scorm from "../components/shared/Scorm/Scorm";
import useLanding from "../hooks/useLanding";

const Landing = () => {
    const { sectionCompanies, sectionImpact, sectionPlatform } = useLanding();

    return (
        <>
            <Navbar />
            <Register />
            <Banner />
            <Courses />
            {/* <Options /> */}
            {sectionPlatform && <Scorm />}
            {sectionImpact && <RequestScorm />}
            {sectionCompanies && <Marks />}
            <Footer />
        </>
    );
};

export default Landing;
