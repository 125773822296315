import React from 'react'
import styled from "styled-components";
import PropTypes from 'prop-types';

const Text = (props) => {

    const { fontSize, fontWeight, color, id, textAlign, children } = props

    return (
        <TextContent textAlign={textAlign} id={id} fontSize={fontSize} fontWeight={fontWeight} color={color}>{children}</TextContent>
    )
}

Text.propTypes = {
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    textAlign: PropTypes.string
}

export default Text

const TextContent = styled.div`       
    color: ${p => p.color};
    font-size: ${p => p.fontSize};
    font-weight: ${p => p.fontWeight} !important;
    text-align: ${p => p.textAlign};
`