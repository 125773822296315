import React, { useState } from 'react'
import styled from 'styled-components'

import notebook from '../../../assets/image/notebook.svg'
import AddIcon from '@mui/icons-material/Add';
import Text from '../../common/Text';
import Button from '@mui/material/Button';
import Image from '../../common/Image';
import Share from '../../common/Share';

const Scorm = () => {


    const [openShare, setOpenShare] = useState(false)

    const onCloseShare = () => setOpenShare(false)
    const onOpenShare = () => setOpenShare(true)

    return (
        <ScormWrapper>
            <ImageContainer>
                <Image src={notebook}/>
            </ImageContainer>
            <Info>
                <Text id="fontTitle" color="#312F4D" fontWeight="700" fontSize="48px">¿Qué encontrarás <br/> en nuestra plataforma?</Text>
                <List>
                    <Item>
                        <AddIcon/>
                        <Text fontSize="18px">Cursos de Impacto cortos y gratuitos</Text>
                    </Item>
                    <Item>
                        <AddIcon/>
                        <Text fontSize="18px">100% remoto</Text>
                    </Item>
                    <Item>
                        <AddIcon/>
                        <Text fontSize="18px">Autogestivos</Text>
                    </Item>
                    <Item>
                        <AddIcon/>
                        <Text fontSize="18px">Certificación AcrópolisLAB</Text>
                    </Item>
                    <Item>
                        <AddIcon/>
                        <Text fontSize="18px">Retroalimentación de experto</Text>
                    </Item>
                </List>
                <Buttons>
                    {/* <Button href="#form" color="primary" variant="contained">Registrarme</Button> */}
                    <Button onClick={onOpenShare} color="primary" variant="contained">Invitar a cursar</Button>
                </Buttons>
                <Share open={openShare} onClose={onCloseShare}/>
            </Info>
            
        </ScormWrapper>
    )
}

export default Scorm

const ScormWrapper = styled.section`
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 32px;
    margin: 5% 0;
    @media (max-width: 768px){
        padding: 12px;
        width: calc(100% - 24px);
        text-align: center;
    }
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px){
        display: none;
    }
`

const Info = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    @media (max-width: 768px){
        width: 100%;
    }
`

const List = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;
    @media (max-width: 768px){
        text-align: start;
    }
`

const Item = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 2px 0;
    p, b{
        color: #616161;
    }
    svg{
        fill: #DC3545;
    }
`

const Buttons = styled.div`
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 18px;
`