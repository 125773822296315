import axios from "axios";
import { adapterLanding } from "../adapters/cms";


const URL = "https://cms.aden.org/api"

export const getOndemand = async param => {
    try {
        const req = await axios.get(`${URL}/landings-impacto-comerciales?filters[url_param][$eq]=${param}&populate=deep`)
        return adapterLanding(req.data.data[0] || false)
    } catch (error) {
        console.error("getOndemand", error);
        throw new Error(error)
    }
}