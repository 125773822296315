import React from "react";
import styled from "styled-components";
import useLanding from "../../../hooks/useLanding";
import Text from "../../common/Text";
import Form from "./components/Form";
import List from "./components/List";

const Register = () => {
    const { textColor, titleHeader, subtitleHeader } = useLanding();

    return (
        <Container id="form">
            <Text color={textColor} fontSize="48px" fontWeight="700">
                {titleHeader}
            </Text>
            <Text color={"#888"} fontSize="18px" fontWeight="600">
                {subtitleHeader}
            </Text>
            <Content>
                <List />
                <Form />
            </Content>
        </Container>
    );
};

export default Register;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 5rem 0;
    @media (max-width: 768px) {
        text-align: center;
    }
`;

const Content = styled.div`
    display: flex;
    gap: 50px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        margin: 0 2rem;
        width: calc(100% - 4rem);
        gap: 0;
    }
`;
